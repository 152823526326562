import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private POST_EMAIL = environment.backendUrl;

  constructor(private http: HttpClient) { }

  postEmail(payload: any) {
    return this.http.post<any>(`${this.POST_EMAIL}/create-user`, payload)
  }

  registerUser(payload: any) {
    return this.http.post<any>(`${this.POST_EMAIL}/notify-user-list`, payload)
  }

  fetchFile(fileId: any) {
    return this.http.get(`${this.POST_EMAIL}/file/${fileId}`, { responseType: 'blob' })
  }

  validateUser(payload: any) {
    return this.http.post<any>(`${this.POST_EMAIL}/validate-user`, payload)
  }
}
