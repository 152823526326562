import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListUserDetailsComponent } from './list-user-details/list-user-details.component'; 

const adminRoutes: Routes = [
  {
    path: 'user-details',
    component: ListUserDetailsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(adminRoutes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
