import { Component, OnInit } from '@angular/core';
import { LISTING_COLUMN_DATA } from '../admin.const';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-list-user-details',
  templateUrl: './list-user-details.component.html',
  styleUrls: ['./list-user-details.component.scss']
})
export class ListUserDetailsComponent implements OnInit {

  programTypes = [];
  userInfomation = [];
  columnData = LISTING_COLUMN_DATA; 

  constructor(private adminService: AdminService) {} 

  ngOnInit() {
    this.fetchUserInformation();
  }
 
  fetchUserInformation(){
    this.adminService.fetchUserDetails().subscribe({
      next: (response: any) => {
        this.userInfomation = response;
        console.log('user informaiton', this.userInfomation)
      },
      error: (err) => {
      }})
  }

  fetchUserId(userId: any) {
    console.log('user if', userId)
  }

}
