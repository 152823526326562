export const ECR = [
    'Father',
    'Mother',
    'Elder Sibling',
    'Younger Sibling',
    'Grandfather',
    'Grandmother',
    'Guardian'
]

export const MEAL_PREF = [
    'Vegetarian',
    'Non-Vegetarian',
    'Eggetarian'
]

export const MEDICAL_COND = [
    'DIABETES',
    'MOTION SICKNESS',
    'ALTITUDE SICKNESS',
    'ASTHAMA',
    'EPILEPSY',
    'HEART RATE or RYTHM DISORDERS',
    'OTHERS'
]

export const GENDER_INFO = ['Male', 'Female', 'Others']