import { Component, HostListener, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';


@Component({
  selector: 'app-website-landing-page',
  templateUrl: './website-landing-page.component.html',
  styleUrls: ['./website-landing-page.component.scss']
})
export class WebsiteLandingPageComponent implements OnInit{
  headerClass = ''
  isMobile: boolean = false;

  constructor(private breakpointObserver: BreakpointObserver) {}

  ngOnInit() {
    this.checkIsMobile();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: any) {
    this.headerClass = '';
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    if(scrollPosition > 200) {  
      this.headerClass = !this.isMobile ? 'change-header-color' : ''
    }
  }

  checkIsMobile() {
    this.breakpointObserver.observe([
      Breakpoints.HandsetPortrait,
      Breakpoints.HandsetLandscape
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });
  }
  
}
