import { Component } from '@angular/core';

@Component({
  selector: 'app-website-landing-page-mobile',
  templateUrl: './website-landing-page-mobile.component.html',
  styleUrls: ['./website-landing-page-mobile.component.scss']
})
export class WebsiteLandingPageMobileComponent {
  headerContent = [{
    name:'Our Story',
    route:'discover'
  }, {
    name:'Our Approach',
    route:'approach'
  },{
    name:'Concepts',
    route:'concept'
  },{
    name:'Join Us',
    route:''
  },{
    name:'SAPIENE',
    route:''
  }, {
    name:'Contact Us',
    route:''
  }]

}
