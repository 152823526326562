<div class="header sticky" [class]="headerClass" *ngIf="!isMobile">
    <app-header></app-header>
</div>
<div class="landing-content-block" *ngIf="!isMobile">
    <div class="website-landing-page-container">
        <div class="landing-page-content">
            <h1 class="main-section-content">
                Masterfully crafted, exclusive, and<br><br> authentic experiences mirrored in you.
            </h1>
            <h3 class="sub-section-content">
                Chaviti designs and curates experiences that celebrate life's richness. For a decade, we have scoured the globe — uncovering hidden gems, forging connections with local experts, savouring local cuisines, immersing ourselves in the local culture, and connecting
                with its people.
                <br><br> Discover the world through our eyes, where every experience is a masterpiece waiting to be painted.
            </h3>
        </div>
        <div class="contact-us-section">
            <button mat-button-raised>Contact Us</button>
        </div>
    </div>
    <div class="your-story-section">
        <div class="discover-more-content">
            <br><br><br><br><br><br>
            <h1 class="main-section-content">
                Your story, your experience — unparalleled,<br><br> unforgettable, and exclusively yours.
            </h1>
            <h3 class="sub-section-content">
                No two people are alike, and neither are their journeys. Through a deep understanding of your likes, dislikes, quirks, desires, motivations, and aspirations, we craft highly personalised experiences that reflect the essence of who you are.
            </h3>
        </div>
        <div class="contact-us-section">
            <button mat-button-raised>Discover More</button>
        </div>
    </div>
    <div class="heriloom-screen">
        <div class="content-section-header">
            <div class="left-side-content">
                A Feeling, an heirloom,<br> a passion, a project, a surprise
            </div>
            <div class="right-side-content">
                It is entirely possible to be homesick for a place you've never been to.
            </div>
        </div>
        <div class="image-container">
            <div class="image-group">
                <div class="image-wrapper image1">
                    <img src="/assets/circus.jpg" alt="Image 1">
                </div>
                <div class="image-wrapper image2">
                    <img src="/assets/postcard.jpg" alt="Image 2">
                </div>
            </div>
            <div class="image-group">
                <div class="image-wrapper image3">
                    <img src="/assets/spices.jpg" alt="Image 3">
                </div>
                <div class="image-wrapper image4">
                    <img src="/assets/car.jpg" alt="Image 4">
                </div>
            </div>
        </div>
        <div class="content-section-bottom">
            Tell us if you crave a specific feeling or emotion after an experience—whether it's exhilaration, serenity, wonder, or anything else—and we'll craft an experience tailored just around that. Share your ideas, prized possessions, or anything you love, and
            we'll transform it into the centrepiece of a mesmerising experience. Honour cherished heirlooms, celebrate family legacy or first generation of entrepreneurship, revisit familiar places with fresh perspectives, indulge in your passions, kickstart
            a project, surprise your loved ones or embark on a new adventure — whatever it is, we're here to make it happen.
        </div>
        <br>
    </div>

    <div class="anywhere-anything-section">
        <div class="anywhere-anything-section-content">
            Anything. Anywhere.<br> Consider It Done.
        </div>
    </div>

    <div class="sanctury-section">
        <br><br>
        <div class="details-section">
            <div class="sanctury-section-content-main">
                A sanctuary for the curious,<br> the dreamers, and the seekers.
            </div>
            <div class="sanctury-section-content-sub-section">
                An extraordinary space for extraordinary people.<br> A space where novel experiences are an art — an art of discovery,<br> of connection, and of the soul — for the spirited, seekers of the <br>extraordinary and individuals who believe
                in the theatre of life.
            </div>
            <br>
        </div>
        <br><br>
    </div>
    <div class="request-callback-section">
        <div class="details-part">
            <div class="landing-page-content-callback">
                <br><br>
                <h2 class="main-section-content-callback">
                    <span class="per-line-space">The world, its people and its</span> <br> <span class="per-line-space">offerings are too magnificent</span><br> to be seen through the lens of the ordinary.
                </h2>
                <br>
                <h3 class="sub-section-content-callback">
                    Share your dreams and desires.<br> Engage in a conversation with us!
                </h3>
            </div>
            <br>
            <div class="request-callback-button">
                <button mat-button-raised>Request A Callback</button>
            </div>
        </div>
        <div class="image-section">
            <img class="image-callback" src="assets/requestcallback.jpg">
        </div>
    </div>
</div>

<!-- Section for mobile View -->
<div class="landing-content-block-mobile-view" *ngIf="isMobile">
    <app-website-landing-page-mobile></app-website-landing-page-mobile>
</div>
<svg xmlns="http://www.w3.org/2000/svg">
    <filter id="blue-tint">
      <feColorMatrix type="matrix" values="1 0 0 0 0 
                                            0 1 0 0 0 
                                            0 0 2 0 0 
                                            0 0 0 1 0" />
    </filter>
</svg>