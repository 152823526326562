import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { ECR, GENDER_INFO, MEAL_PREF, MEDICAL_COND } from '../commons/common.const';
import { ContactService } from '../contact.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}


function allowedValuesValidator(allowedValues: string[]) {
  return (control: FormControl) => {
    if (!control.value) {
      return null; // No input provided
    }

    if (allowedValues.includes(control.value)) {
      return null; // Input is one of the allowed values
    }

    return { 'invalidValue': true }; // Input is not in the allowed set
  };
}

export function indianMobileNumberValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const phoneNumber = control.value;
    const pattern = /^[789]\d{9}$/;

    if (!pattern.test(phoneNumber)) {
      return { 'indianMobileNumber': true };
    }

    return null;
  };
}
@Component({
  selector: 'app-form-modules',
  templateUrl: './form-modules.component.html',
  styleUrls: ['./form-modules.component.scss']
})
export class FormModulesComponent {
  title = 'chaviti-website';
  pattern=/06([0-9]{8})/;
  emailFormControl = new FormControl('', [Validators.email]);
  matcher = new MyErrorStateMatcher();
  userFormControl = new FormControl('' , [Validators.required]);
  phoneNumberFromControl =  new FormControl('' , [Validators.maxLength(10), Validators.minLength(10)])
  hasResponseSubmitted = false;
  userDetailsFormControl: FormGroup;
  genderInfo = GENDER_INFO;
  emergencyRel = ECR;
  mealPref = MEAL_PREF;
  medicalCondition = MEDICAL_COND;
  medicalConditionValue = '';
  otherMedicalCondition = '';
  selectFileInfo:any;
  userId = '';

  constructor(private contactService: ContactService, private formBuilder: FormBuilder,
    private http: HttpClient) {
    this.userDetailsFormControl = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      middleName: new FormControl(''),
      lastName: new FormControl('', [Validators.required]),
      dateOfBirth: new FormControl('' , [Validators.required]),
      gender: new FormControl('', [
        Validators.required,
        allowedValuesValidator(GENDER_INFO) as ValidatorFn
      ]),
      phoneNumber: new FormControl('', [
        Validators.required,
        indianMobileNumberValidator()
      ]),
      email: new FormControl('', [
        Validators.email
      ]),
      emerContactName: new FormControl('', [Validators.required]),
      emerContactNumber: new FormControl('', [
        Validators.required,
        indianMobileNumberValidator()
      ]),
      emerContactRelation: new FormControl('',[
        Validators.required,
        allowedValuesValidator(ECR) as ValidatorFn]),
      mealPref: new FormControl('',[
        Validators.required,
        allowedValuesValidator(MEAL_PREF) as ValidatorFn ]),
      fileUpload: new FormControl(''),
      medicalCondition: new FormControl('',[
        Validators.required,
        allowedValuesValidator(MEDICAL_COND) as ValidatorFn]),
      otherMedicalCondition: new FormControl(''),
    });
  
  }

// onFileSelected(event: any): void {
//   const file: File = event.target.files[0];
//   if (file) {
//     // Do something with the selected file (e.g., store it in a variable, upload to a server, etc.)
//     console.log('Selected file:', file);
//   }
// }

onFileSelected(event: any): void {
  this.selectFileInfo = event.target.files[0];
}

onSubmit(): void {
  const formData = new FormData();
  formData.append('file', this.selectFileInfo);
  formData.append('user_id', this.userId);
  // Send the data to the server
  this.http.post('https://app.chaviti.com/file-upload', formData).subscribe(
    {
      next: (response: any) => {
        console.log('Response:', response);
      },
      error: (err) => {
        console.error('Error:', err);
      }}
  );
}


  saveText(){
    this.hasResponseSubmitted = false;
    const payload = {
      first_name: this.userDetailsFormControl.get('firstName')?.value,
      middle_name:this.userDetailsFormControl.get('middleName')?.value,
      last_name: this.userDetailsFormControl.get('lastName')?.value,
      dob: this.userDetailsFormControl.get('dateOfBirth')?.value,
      gender: this.userDetailsFormControl.get('gender')?.value,
      phone_number: this.userDetailsFormControl.get('phoneNumber')?.value,
      email:this.userDetailsFormControl.get('email')?.value,
      ec_name: this.userDetailsFormControl.get('emerContactName')?.value,
      ec_number: this.userDetailsFormControl.get('emerContactNumber')?.value,
      ec_relation: this.userDetailsFormControl.get('emerContactRelation')?.value,
      meal: this.userDetailsFormControl.get('mealPref')?.value,
      medical_condition: this.userDetailsFormControl.get('medicalCondition')?.value === 'OTHERS' ? 
      this.userDetailsFormControl.get('otherMedicalCondition')?.value : this.userDetailsFormControl.get('medicalCondition')?.value,
      college_name: 'Gitam'
    }
    console.log('informaiton', payload)

    this.contactService.postEmail(payload).subscribe({
      next: (response: any) => {
        this.userId = response.user_id;
        this.onSubmit();
        console.log('response', response)
        this.hasResponseSubmitted = true;
      },
      error: (err) => {
        this.hasResponseSubmitted = false;
      }})
  }

  showFile() {
    const fileId = 1
    this.contactService.fetchFile(fileId).subscribe({
      next: (response: any) => {
        console.log('response', response)
      },
      error: (err) => {
      }})
  }

  viewFile() {
    const fileId = 1

    this.contactService.fetchFile(fileId).subscribe((data: Blob) => {
      const fileURL = URL.createObjectURL(data);
      console.log('fileurl', fileURL);
      window.open(fileURL); // This opens the file in a new tab.
    });
  }
}