import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private POST_EMAIL = environment.backendUrl;


  constructor(private http: HttpClient) { }

  fetchUserDetails() {
    return this.http.get<any>(`${this.POST_EMAIL}/fetch-userinfo`)
  }

}
