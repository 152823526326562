import { Component } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  headerContent = [{
    name:'Our Story',
    route:'discover'
  }, {
    name:'Our Approach',
    route:'approach'
  },{
    name:'Concepts',
    route:'concept'
  },{
    name:'Join Us',
    route:''
  },{
    name:'SAPIENE',
    route:''
  }, {
    name:'Contact Us',
    route:''
  }]
  defineClass = '';

  openHeader(sideNav: any) {
    const stateOfSideNavigation  = sideNav._animationState;
    console.log('show value', sideNav, stateOfSideNavigation)
    if(stateOfSideNavigation === 'open') {
      this.defineClass = '';
    } else {
      this.defineClass = 'chaviti-header-container-info'
    }
    sideNav.toggle();
  }
}
