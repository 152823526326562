<div class="admin-container">
    <h1>Admin Handler</h1>
    <div class="select-program-type">
        <mat-label>Select Program Type</mat-label>
        <mat-select>
            <mat-option *ngFor="let programInfo of programTypes" [value]="programInfo">
                {{programInfo}}
            </mat-option>
        </mat-select>
    </div>
    <div class="user-details-table">
        <table mat-table [dataSource]="userInfomation" class="mat-elevation-z8">

            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

            <!-- Position Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> Id </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="college_name">
                <th mat-header-cell *matHeaderCellDef> College Name </th>
                <td mat-cell *matCellDef="let element"> {{element.college_name}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="first_name">
                <th mat-header-cell *matHeaderCellDef> First Name </th>
                <td mat-cell *matCellDef="let element"> {{element.first_name}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="middle_name">
                <th mat-header-cell *matHeaderCellDef> Middle Name </th>
                <td mat-cell *matCellDef="let element"> {{element.middle_name}} </td>
            </ng-container>

            <ng-container matColumnDef="last_name">
                <th mat-header-cell *matHeaderCellDef> Last Name </th>
                <td mat-cell *matCellDef="let element"> {{element.last_name}} </td>
            </ng-container>

            <ng-container matColumnDef="dob">
                <th mat-header-cell *matHeaderCellDef> Date of Birth </th>
                <td mat-cell *matCellDef="let element"> {{element.dob}} </td>
            </ng-container>

            <ng-container matColumnDef="gender">
                <th mat-header-cell *matHeaderCellDef> gender </th>
                <td mat-cell *matCellDef="let element"> {{element.gender}} </td>
            </ng-container>

            <ng-container matColumnDef="phone_number">
                <th mat-header-cell *matHeaderCellDef> Phone Number </th>
                <td mat-cell *matCellDef="let element"> {{element.phone_number}} </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef> Email </th>
                <td mat-cell *matCellDef="let element"> {{element.email}} </td>
            </ng-container>

            <ng-container matColumnDef="ec_name">
                <th mat-header-cell *matHeaderCellDef> Emergency Contact Name </th>
                <td mat-cell *matCellDef="let element"> {{element.ec_name}} </td>
            </ng-container>

            <ng-container matColumnDef="ec_number">
                <th mat-header-cell *matHeaderCellDef> Emergency Contact Number </th>
                <td mat-cell *matCellDef="let element"> {{element.ec_number}} </td>
            </ng-container>


            <ng-container matColumnDef="ec_relation">
                <th mat-header-cell *matHeaderCellDef> Emergency Contact Relation </th>
                <td mat-cell *matCellDef="let element"> {{element.ec_relation}} </td>
            </ng-container>


            <ng-container matColumnDef="meal">
                <th mat-header-cell *matHeaderCellDef> Meal</th>
                <td mat-cell *matCellDef="let element"> {{element.meal}} </td>
            </ng-container>



            <ng-container matColumnDef="medical_condition">
                <th mat-header-cell *matHeaderCellDef> Medical Condition</th>
                <td mat-cell *matCellDef="let element"> {{element.medical_condition}} </td>
            </ng-container>

            <ng-container matColumnDef="fetch_id">
                <th mat-header-cell *matHeaderCellDef> Fetch User ID</th>
                <td mat-cell *matCellDef="let element">
                    <button mat-raised-button color="accent" (click)="fetchUserId(element.id)">Fetch</button>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="columnData"></tr>
            <tr mat-row *matRowDef="let row; columns: columnData;"></tr>
        </table>
    </div>
</div>