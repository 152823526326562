<div class="container">
    <img class="background-image" src="assets/background-image.jpg">
    <div class="centered" *ngIf="!hasResponseSubmitted">
        <h1>Chaviti is down for modernisation!</h1>
        <div class="notify-section">
            <form class="email-form" [formGroup]="userDetailsFormControl">
                <mat-form-field class="content-full-width">
                    <mat-label>First Name</mat-label>
                    <input type="text" matInput formControlName="firstName" name="firstName">
                </mat-form-field>
                <mat-form-field class="content-full-width">
                    <mat-label>Middle Name</mat-label>
                    <input type="text" matInput formControlName="middleName">
                </mat-form-field>
                <mat-form-field class="content-full-width">
                    <mat-label>Last Name</mat-label>
                    <input type="text" matInput formControlName="lastName">
                </mat-form-field>
                <mat-form-field class="content-full-width">
                    <mat-label>DOB</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="dateOfBirth">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="content-full-width">
                    <mat-label>Gender</mat-label>
                    <mat-select formControlName="gender">
                        <mat-option *ngFor="let gender of genderInfo" [value]="gender">
                            {{gender}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="content-full-width">
                    <mat-label>Phone Number</mat-label>
                    <input type="text" matInput formControlName="phoneNumber">
                </mat-form-field>
                <mat-form-field class="content-full-width">
                    <mat-label>Email</mat-label>
                    <input type="email" matInput formControlName="email" [errorStateMatcher]="matcher" placeholder="Ex. pat@example.com">
                </mat-form-field>
                <mat-form-field class="content-full-width">
                    <mat-label>Emergency Contact Name</mat-label>
                    <input type="text" matInput formControlName="emerContactName" name="emerContactName">
                </mat-form-field>
                <mat-form-field class="content-full-width">
                    <mat-label>Emergency Phone Number</mat-label>
                    <input type="text" matInput formControlName="emerContactNumber" name="emerContactNumber">
                </mat-form-field>
                <mat-form-field class="content-full-width">
                    <mat-label>Emergency Contact Relation</mat-label>
                    <mat-select formControlName="emerContactRelation">
                        <mat-option *ngFor="let emergency of emergencyRel" [value]="emergency">
                            {{emergency}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="content-full-width">
                    <mat-label>Meal Preferance</mat-label>
                    <mat-select formControlName="mealPref">
                        <mat-option *ngFor="let meal of mealPref" [value]="meal">
                            {{meal}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="content-full-width">
                    <mat-label>Medical Condition</mat-label>
                    <mat-select formControlName="medicalCondition">
                        <mat-option *ngFor="let medCond of medicalCondition" [value]="medCond">
                            {{medCond}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="content-full-width" *ngIf="userDetailsFormControl.get('medicalCondition')?.value == 'OTHERS'">
                    <mat-label>Enter Condition Here</mat-label>
                    <input matInput type="text" formControlName="otherMedicalCondition" name="otherMedicalCondition">
                </mat-form-field>
                <!-- <input type="file" (change)="onFileSelected($event)" accept=".jpg, .jpeg, .png"> -->
                <div class="button-wrapper">
                    <span class="label">
                        <mat-label formControlName="fileUpload" name="fileUpload">Select a file</mat-label>
                    </span>
                    <input type="file" name="upload" id="upload" class="upload-box" placeholder="Upload File" (change)="onFileSelected($event)">
                </div>
                <br>
            </form>
            <button class="notify-button" [disabled]="!userDetailsFormControl.valid" mat-raised-button color="accent" (click)="saveText()">Submit</button>
        </div>
    </div>
    <div class="centered response-on-success" *ngIf="hasResponseSubmitted">
        <div class="text-on-submission">
            Thank You for Registering
        </div>
    </div>
</div>